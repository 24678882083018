.contact11-mark {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contact11-link {
  display: contents;
}
.contact11-icon {
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: #414141;
}
.contact11-icon1 {
  width: 24px;
  height: 24px;
}
.contact11-link1 {
  display: contents;
}
.contact11-mark1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.contact11-icon3 {
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  background-color: #414141;
}
.contact11-icon4 {
  width: 24px;
  height: 24px;
}






















@media(max-width: 1600px) {
  .contact11-icon {
    background-color: var(--dl-color-primary-300);
  }
  .contact11-icon3 {
    background-color: var(--dl-color-primary-300);
  }
}
