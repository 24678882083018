.review111-card {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #292929;
}
.review111-stars {
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.review111-icon {
  fill: #FFDE6A;
  width: 16px;
  height: 16px;
}
.review111-icon02 {
  fill: #FFDE6A;
  width: 16px;
  height: 16px;
}
.review111-icon04 {
  fill: #FFDE6A;
  width: 16px;
  height: 16px;
}
.review111-icon06 {
  fill: #FFDE6A;
  width: 16px;
  height: 16px;
}
.review111-icon08 {
  fill: #FFDE6A;
  width: 16px;
  height: 16px;
}
.review111-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.review111-quote {
  color: #CCCCCC;
  font-size: 18px;
  font-family: Poppins;
  line-height: 25px;
}
.review111-author {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.review111-details {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.review111-author1 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.review111-position {
  color: #CCCCCC;
  font-size: 14px;
  font-family: Poppins;
  line-height: 20px;
}







@media(max-width: 991px) {
  .review111-card {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
