.card1-card {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #292929;
}
.card1-icon {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  aspect-ratio: 1;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  background-color: #80FF44;
}
.card1-icon01 {
  width: 24px;
  height: 24px;
}
.card1-content {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.card1-header {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 36px;
}
.card1-description {
  color: #C2C6CC;
  font-family: Poppins;
  line-height: 28px;
}




@media(max-width: 1600px) {
  .card1-icon {
    background-color: var(--dl-color-primary-300);
  }
}
@media(max-width: 1200px) {
  .card1-icon {
    background-color: var(--dl-color-primary-300);
  }
}
