.contact1-mark {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contact1-icon {
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  background-color: #414141;
}
.contact1-icon1 {
  width: 24px;
  height: 24px;
}
.contact1-label {
  color: #C2C6CC;
  font-size: 14px;
  font-family: Poppins;
  line-height: 25px;
}






















@media(max-width: 1600px) {
  .contact1-icon {
    background-color: var(--dl-color-primary-300);
  }
}
