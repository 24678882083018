.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  background-color: #0F0F0F;
}
.home-section1 {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  border-bottom-width: 1px;
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-content {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}
.home-main {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading {
  color: rgb(255, 255, 255);
  font-size: 64px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-caption {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-family: Poppins;
  line-height: 30px;
}
.home-buttons {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-link {
  display: contents;
}
.home-get-started {
  display: flex;
  text-decoration: none;
  background-color: var(--dl-color-primary-300);
}
.home-text {
  color: #0C100C;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-get-started1 {
  background-color: #2A2A2A;
}
.home-text01 {
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-highlight {
  gap: var(--dl-space-space-unit);
  width: 695px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-avatars {
  width: 115px;
  height: var(--dl-size-size-small);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
.home-image {
  left: 0px;
  position: absolute;
}
.home-image1 {
  left: var(--dl-space-space-twounits);
  position: absolute;
  object-fit: cover;
}
.home-image2 {
  left: var(--dl-space-space-fourunits);
  position: absolute;
  object-fit: cover;
}
.home-caption1 {
  color: rgb(255, 255, 255);
  font-family: Poppins;
  line-height: 24px;
}
.home-image3 {
  top: 150px;
  right: 0px;
  width: 650px;
  height: 900px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image4 {
  top: 0px;
  right: 0px;
  width: 866px;
  margin: auto;
  position: absolute;
  object-fit: cover;
}
.home-image5 {
  display: none;
}
.home-section2 {
  gap: var(--dl-space-space-sixunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
}
.home-header1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text02 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-text03 {
  color: #C2C6CC;
  width: 100%;
  max-width: 600px;
  text-align: center;
  font-family: Poppins;
  line-height: 28px;
}
.home-note {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image7 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-pasted-image {
  object-fit: cover;
}
.home-content1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-main1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-heading1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-get-started2 {
  display: flex;
  background-color: var(--dl-color-primary-300);
}
.home-text20 {
  color: #0C100C;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-note1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
}
.home-image8 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-pasted-image1 {
  width: 672px;
  height: 588px;
  object-fit: cover;
}
.home-content2 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-main2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-heading3 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-checkmarks {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-mark {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-icon {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  background-color: #292929;
}
.home-icon1 {
  fill: #C6FF4B;
  width: 12px;
  height: 12px;
}
.home-label {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 24px;
}
.home-mark1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-icon3 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  background-color: #292929;
}
.home-icon4 {
  fill: #C6FF4B;
  width: 12px;
  height: 12px;
}
.home-label1 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 24px;
}
.home-get-started3 {
  display: flex;
  background-color: var(--dl-color-primary-500);
}
.home-text21 {
  color: #0C100C;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-section {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  border-top-width: 1px;
}
.home-header3 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-left {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-right {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-paragraph2 {
  width: 100%;
  max-width: 480px;
}
.home-cards {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-section3 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  border-top-width: 1px;
}
.home-header4 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-left1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading6 {
  text-align: center;
}
.home-right1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-paragraph3 {
  width: 100%;
  max-width: 600px;
  text-align: center;
}
.home-cards1 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-threeunits);
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  grid-template-columns: auto auto auto;
}
.home-container1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-container2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-container3 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-view-more {
  gap: var(--dl-space-space-oneandhalfunits);
  cursor: pointer;
  display: none;
  transition: 0.3s;
  align-items: center;
  border-color: #80FF44;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  border-bottom-width: 1px;
}
.home-view-more:hover {
  opacity: 0.5;
}
.home-text22 {
  color: rgb(128, 255, 68);
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.home-section4 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  background-color: #292929;
}
.home-content3 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-header5 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: flex-start;
}
.home-heading7 {
  text-align: left;
}
.home-paragraph4 {
  width: 100%;
  max-width: 600px;
  text-align: center;
}
.home-text23 {
  width: 100%;
  max-width: 600px;
  text-align: center;
}
.home-pasted-image2 {
  right: 0px;
  width: 470px;
  bottom: 0px;
  position: absolute;
  object-fit: cover;
}
.home-footer {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-content4 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-main-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-content5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-main3 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-branding {
  width: 120px;
  object-fit: cover;
}
.home-text28 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  max-width: 260px;
  font-family: Poppins;
  line-height: 21px;
}
.home-socials {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-link1 {
  display: contents;
}
.home-link2 {
  display: contents;
}
.home-categories {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-category {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-links {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-copyright {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text32 {
  color: #C4C4C4;
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}
.home-subscribe {
  gap: var(--dl-space-space-unit);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-main4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading8 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  max-width: 275px;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  line-height: 30px;
}
.home-input-field {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-radius: 50px;
  flex-direction: row;
  justify-content: center;
  background-color: #292929;
}
.home-link3 {
  display: contents;
}
.home-buy {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: #80FF44;
}
.home-text33 {
  color: rgb(12, 16, 12);
  display: none;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-text34 {
  color: rgb(12, 16, 12);
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-notice {
  color: #686868;
  font-size: 14px;
  max-width: 400px;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  line-height: 21px;
}
.home-copyright1 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text37 {
  color: #C4C4C4;
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}
.home-container5 {
  display: contents;
}
@media(max-width: 1600px) {
  .home-heading {
    color: rgb(255, 255, 255);
    font-size: 64px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    padding-right: 0px;
  }
  .home-caption {
    color: rgb(255, 255, 255);
  }
  .home-get-started {
    background-color: var(--dl-color-primary-100);
  }
  .home-text {
    color: rgb(12, 16, 12);
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
  .home-get-started1 {
    width: 268px;
  }
  .home-text01 {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
  .home-highlight {
    width: 701px;
  }
  .home-image4 {
    width: 744px;
  }
  .home-text02 {
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
  }
  .home-get-started2 {
    background-color: #44d2ff;
  }
  .home-paragraph4 {
    font-size: 22px;
    text-align: left;
  }
  .home-text23 {
    color: var(--dl-color-theme-secondary1);
    font-style: normal;
    text-align: left;
    font-weight: 800;
  }
  .home-text26 {
    font-size: 20px;
  }
  .home-pasted-image2 {
    top: -228px;
    right: 15px;
    width: 426px;
    height: 658px;
  }
  .home-text28 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-family: Poppins;
    line-height: 21px;
  }
  .home-text32 {
    color: rgb(196, 196, 196);
  }
  .home-heading8 {
    color: rgb(255, 255, 255);
  }
  .home-buy {
    background-color: var(--dl-color-primary-300);
  }
  .home-text34 {
    color: rgb(12, 16, 12);
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
  .home-notice {
    color: rgb(104, 104, 104);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
}
@media(max-width: 1200px) {
  .home-heading {
    color: rgb(255, 255, 255);
    width: 586px;
    height: 266px;
    font-size: 64px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    margin-right: 0px;
  }
  .home-caption {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-get-started {
    background-color: var(--dl-color-primary-300);
  }
  .home-text {
    color: rgb(12, 16, 12);
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
  .home-caption1 {
    color: rgb(255, 255, 255);
  }
  .home-image4 {
    width: 610px;
    height: 615px;
  }
  .home-text02 {
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
  }
  .home-text03 {
    color: rgb(194, 198, 204);
    max-width: 100%;
    font-family: Poppins;
    line-height: 28px;
  }
  .home-pasted-image {
    width: 509px;
  }
  .home-get-started2 {
    background-color: var(--dl-color-primary-300);
  }
  .home-text20 {
    color: rgb(12, 16, 12);
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
  .home-label {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 24px;
  }
  .home-label1 {
    color: rgb(255, 255, 255);
  }
  .home-get-started3 {
    background-color: var(--dl-color-primary-300);
  }
  .home-text21 {
    color: rgb(12, 16, 12);
  }
}
@media(max-width: 991px) {
  .home-section1 {
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-hero {
    gap: var(--dl-space-space-fourunits);
    flex: 1;
    position: relative;
    max-width: auto;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-content {
    position: relative;
    align-items: center;
  }
  .home-main {
    align-items: center;
  }
  .home-heading {
    text-align: left;
  }
  .home-caption {
    text-align: left;
  }
  .home-buttons {
    width: 100%;
  }
  .home-get-started {
    flex: 1;
  }
  .home-get-started1 {
    flex: 1;
  }
  .home-highlight {
    width: 100%;
    flex-direction: column;
  }
  .home-image3 {
    right: 0px;
    display: none;
    position: absolute;
    flex-direction: column;
  }
  .home-image5 {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .home-image6 {
    width: 100%;
  }
  .home-text02 {
    text-align: center;
  }
  .home-pasted-image1 {
    width: 517px;
    height: 488px;
  }
  .home-section {
    gap: var(--dl-space-space-threeunits);
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .home-header3 {
    gap: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-cards {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-section3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-cards1 {
    grid-template-columns: auto;
  }
  .home-container3 {
    display: none;
  }
  .home-view-more {
    display: flex;
    border-color: #CCCCCC;
  }
  .home-text22 {
    color: #CCCCCC;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .home-content3 {
    align-items: center;
    flex-direction: column;
  }
  .home-pasted-image2 {
    position: static;
  }
  .home-content4 {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-copyright {
    display: none;
  }
  .home-copyright1 {
    display: flex;
  }
}
@media(max-width: 767px) {
  .home-content {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading {
    height: 161px;
    font-size: 40px;
  }
  .home-caption {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
  }
  .home-get-started {
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-get-started1 {
    display: none;
  }
  .home-caption1 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-image6 {
    width: 100%;
  }
  .home-section2 {
    gap: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text02 {
    font-size: 32px;
    text-align: center;
  }
  .home-note {
    flex-direction: column-reverse;
  }
  .home-note1 {
    flex-direction: column-reverse;
  }
  .home-pasted-image1 {
    width: 676px;
  }
  .home-left {
    gap: var(--dl-space-space-unit);
  }
  .home-section3 {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-section4 {
    padding-top: var(--dl-space-space-fourunits);
  }
  .home-header5 {
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-content5 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-main3 {
    gap: var(--dl-space-space-twounits);
  }
  .home-categories {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-text34 {
    color: rgb(12, 16, 12);
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
}
@media(max-width: 479px) {
  .home-header {
    height: 324px;
    align-items: flex-start;
  }
  .home-heading {
    width: 347px;
    height: 243px;
    max-width: 280px;
  }
  .home-image6 {
    width: 100%;
  }
  .home-pasted-image1 {
    width: 478px;
    height: 356px;
  }
  .home-pasted-image2 {
    width: 100%;
  }
  .home-branding {
    padding-top: var(--dl-space-space-unit);
  }
  .home-text33 {
    color: rgb(12, 16, 12);
    display: flex;
    font-size: 24px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 24px;
    white-space: nowrap;
  }
  .home-text34 {
    color: rgb(12, 16, 12);
    display: none;
    font-size: 24px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 24px;
  }
}
